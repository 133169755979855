import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTE, APP_TITLE } from '../../constants';
import {
  FolderIcon,
  RadioIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import Lines from './../../assets/images/lines.png';
import AnimatedVideo from './../../assets/images/videoanimation.png';
import Reveal from '../../shared/Reveal';
import { motion, useInView, useAnimation } from 'framer-motion';

const FrontpageTop = () => {
  const navigate = useNavigate();
  const [incentives] = useState([
    {
      name: APP_TITLE.LIVE_RADIO,
      icon: RadioIcon,
      description:
        'The best live AI stations from around the country, any time, any place',
      href: APP_ROUTE.LIVE_RADIO,
    },
    {
      name: APP_TITLE.AI_RADIO,
      icon: SparklesIcon,
      description: 'AI generated playlists you can listen to anytime',
      href: APP_ROUTE.AI_RADIO,
    },
    {
      name: APP_TITLE.PODCASTS,
      icon: FolderIcon,
      description:
        'Exclusive podcasts in a variety of genres and topics made with AI',
      href: APP_ROUTE.PODCASTS,
    },
  ]);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
    }
  }, [isInView, mainControls]);

  const fadeInAnimation = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1 * index,
      },
    }),
  };

  return (
    <div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 mb-[100px]">
        <div className="grid grid-cols-1 items-center lg:grid-cols-2 bg-[#f4f5fb] rounded-2xl">
          <div className="p-12">
            <Reveal
              hidden={{ opacity: 0, scale: 0 }}
              visible={{ opacity: 1, scale: 1 }}
              width="full"
            >
              <h2 className="text-4xl font-bold text-gray-900 leading-[120%]">
                Reinvent Radio with the Power of AI
              </h2>
            </Reveal>
            <Reveal
              hidden={{ opacity: 0, y: 100 }}
              visible={{ opacity: 1, y: 1 }}
              width="full"
            >
              <p className="mt-4 text-gray-500">
                Listen to example AI radio stations and hear for yourself how
                good radio entirely produced and presented by AI can be.
              </p>
            </Reveal>
          </div>
          <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg bg-gray-100 relative p-12">
            <Reveal
              hidden={{ opacity: 0, x: 100 }}
              visible={{ opacity: 1, x: 1 }}
              width="full"
            >
              <img
                src={AnimatedVideo}
                alt="lines"
                className="absolute -right-7 -top-7"
              />
            </Reveal>
            <Reveal
              hidden={{ opacity: 0, scale: 0 }}
              visible={{ opacity: 1, scale: 1 }}
              width="full"
              className="flex justify-center items-center mt-5"
            >
              <video
                className="object-cover w-full rounded-2xl"
                src="https://firebasestorage.googleapis.com/v0/b/podcastsite-1bedc.appspot.com/o/audio-files%2Floop.mp4?alt=media&token=194fde34-b666-4e2b-a40f-52265f3c6062"
                alt=""
                autoPlay
                loop
                muted
              />
            </Reveal>

            <Reveal
              hidden={{ opacity: 0, y: 100, scale: 0 }}
              visible={{ opacity: 1, y: 1, scale: 1 }}
              width="full"
              className="flex justify-center items-center mt-5"
            >
              <img src={Lines} alt="lines" />
            </Reveal>
          </div>
        </div>
      </div>

      <div className="bg-gray-900">
        <div className="py-20 px-4 sm:px-6 md:px-8 mt-16 max-w-7xl mx-auto">
          <div
            className="grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3"
            ref={ref}
          >
            {incentives.map((item, index) => (
              <motion.div
                variants={fadeInAnimation}
                initial="initial"
                whileInView="animate"
                custom={index}
                animate={mainControls}
                key={item.name}
              >
                <div className="h-full sm:flex lg:block bg-gray-700/70 hover:bg-gray-600/70 hover:transform hover:-translate-y-2 backdrop-blur-sm tranmsition duration-200 p-5 rounded-2xl">
                  <div className="sm:flex-shrink-0">
                    <item.icon className="h-16 w-16 text-white" />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                    <h3
                      className="text-gray-200 pointer text-[22px] tracking-tight font-bold"
                      onClick={() => {
                        navigate(item.href);
                      }}
                    >
                      {item.name}
                    </h3>
                    <p className="mt-3 text-base text-gray-500 text-[18px] font-medium">
                      {item.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontpageTop;

import React from 'react';

export default function PlayPauseIcon({ isPlaying }) {
  return (
    <div>
      {' '}
      <svg
        version="1.1"
        id="play"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
        xmlSpace="preserve"
      >
        <path
          className="stroke-solid"
          fill="none"
          stroke="#ffffff"
          d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7C97.3,23.7,75.7,2.3,49.9,2.5"
        />
        {isPlaying ? (
          <>
            <path
              strokeLinecap="round"
              stroke="#ffffff"
              strokeWidth={2}
              className="transform translate-x-[22px] translate-y-[20px] scale-[2.5]"
              strokeLinejoin="round"
              d="M15.75 5.25v13.5m-7.5-13.5v13.5"
            />
          </>
        ) : (
          <>
            <path
              strokeLinecap="round"
              fill="#ffffff"
              className="transform translate-x-[24px] translate-y-[20px] scale-[2.5]"
              strokeLinejoin="round"
              d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
            />
          </>
        )}
      </svg>
    </div>
  );
}

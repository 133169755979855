import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setGlobalPlayer,
  setNextGlobalPlayer,
  setProducts,
} from '../store/appSlice';
import { getStationMusic } from '../services/cms.service';

const PlaylistItemList = () => {
  const dispatch = useDispatch();
  const { searchText, aiRadios } = useSelector((state) => state.appStore);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (searchText === '') {
      setItems(aiRadios);
    } else {
      setItems(
        aiRadios?.filter((item) => {
          return (
            (item?.name?.toLowerCase() ?? '')?.includes(
              searchText?.toLowerCase()
            ) ||
            (item?.description?.toLowerCase() ?? '')?.includes(
              searchText?.toLowerCase()
            )
          );
        })
      );
    }
    return () => {};
  }, [searchText, aiRadios]);

  const handleSelectRadio = (item) => {
    getStationMusic(item.station_id).then((data) => {
      dispatch(setProducts({ type: 'aiRadioPlaylist', data }));
      dispatch(
        setGlobalPlayer({ ...data[0], logo: item.logo, type: 'aiRadio' })
      );
    });
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Products</h2>

        <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {items?.map((product, key) => (
            <div
              key={key}
              className="live-grid-item"
              onClick={() => handleSelectRadio(product)}
            >
              <div className="group">
                <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
                  <img
                    alt={product.name}
                    src={product.logo}
                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
                <p className="mt-1 text-lg font-medium text-gray-900">
                  {product.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlaylistItemList;
